import React, { useState, useEffect } from "react"
import { Head as DefaultHead } from "../head"
import { Script } from "gatsby"

function Slides() {
  const style = {
    fontFamily: ["Palanquin", "ui-sans-serif", "system-ui"],
    background: "#171619",
    color: "#fff",
  }

  return (
    <main style={style}>
      <article id="webslides" className="horizontal">
        <Slide
          bgImage={
            <span
              className="background bg-cover"
              style={{
                backgroundImage: `url('/assets/webslides/frameland/title.png')`,
                backgroundSize: "contain",
              }}
            ></span>
          }
        />

        <Slide bgImage={<Trailer />}>
          <a href="https://www.youtube.com/watch?v=T87JjxBXSng" target="_blank">
            link
          </a>
        </Slide>

        <Slide title="Premise">
          <p className="leading-normal">
            A short RPG that <br />
            you can play over the weekend.
          </p>
          <span className="block ml-0 mt-8 text-gray-400">6 - 8 hours</span>
        </Slide>

        <Slide>
          <Liste
            items={[
              "Long cutscenes",
              "Endless character monologues",
              "Grinding levels",
              "Filler content",
            ]}
          />
        </Slide>

        <Slide title="Turn Based Combat">
          <div className="mt-6"></div>
          <Liste items={["🕙 Time everything", "⚔️ Boost damage", "🛡️ Block attacks"]} />
        </Slide>

        <Slide
          title="Timed Attacks"
          bgImage={
            <div className="video-container">
              <video
                playsInline
                muted
                autoPlay
                loop
                className="video background bg-contain"
                style={{ maxHeight: "80vh" }}
              >
                <source src={`/assets/webslides/frameland/Attacks.mp4`} type="video/mp4" />
              </video>
            </div>
          }
        ></Slide>

        <Slide
          title="Blocks"
          bgImage={
            <div className="video-container">
              <video
                playsInline
                muted
                autoPlay
                loop
                className="video background bg-contain"
                style={{ maxHeight: "80vh" }}
              >
                <source src={`/assets/webslides/frameland/Defends.mp4`} type="video/mp4" />
              </video>
            </div>
          }
        ></Slide>

        <Slide
          title="Healing"
          bgImage={
            <div className="video-container">
              <video
                playsInline
                muted
                autoPlay
                loop
                className="video background bg-contain"
                style={{ maxHeight: "80vh" }}
              >
                <source src={`/assets/webslides/frameland/Healing.mp4`} type="video/mp4" />
              </video>
            </div>
          }
        ></Slide>

        <Slide title="Strategy">
          <Liste items={["Exploit enemy weaknesses", "Gather AP → use powerful attacks"]} />
        </Slide>

        <Slide
          title="Exploration"
          bgImage={
            <span
              className="background bg-cover"
              style={{
                backgroundImage: `url('/assets/webslides/frameland/explore.png')`,
                backgroundSize: "contain",
              }}
            ></span>
          }
        >
          <>
            <Liste
              items={["Interact with almost anything", "Lots of hidden stuff", "Pet dogs 🐕"]}
            />
            <span className="block ml-0 mt-8 text-gray-400">(all optional)</span>
          </>
        </Slide>

        <Slide
          topTitle="Exploration"
          bgImage={
            <span
              className="background bg-cover"
              style={{
                backgroundImage: `url('/assets/webslides/frameland/explore_intro.png')`,
                backgroundSize: "contain",
              }}
            ></span>
          }
        ></Slide>

        <Slide title="Website">
          <img src="/assets/webslides/frameland/website.png" draggable="false" />
          <a href="https://frameland.net/" className="underline inline-block mt-16">
            https://frameland.net/
          </a>
        </Slide>

        <Slide title="Steam">
          <img src="/assets/webslides/frameland/steam.png" draggable="false" />
          <a
            href="https://store.steampowered.com/app/2137750/Frameland_A_Binary_Tale/"
            className="underline inline-block mt-16"
          >
            https://store.steampowered.com/app/2137750/Frameland_A_Binary_Tale/
          </a>
        </Slide>

        <Slide>
          <>
            <p>Thank you!</p>
            <p className="mt-8 text-gray-500" style={{ fontSize: "0.8em" }}>
              Markus Langthaler | Sebastian Höhnl | Mario Dederichs
            </p>
          </>
        </Slide>

        <Slide
          bgImage={
            <span
              className="background bg-cover"
              style={{
                backgroundImage: `url('/assets/webslides/frameland/editor.png')`,
                backgroundSize: "contain",
              }}
            ></span>
          }
        ></Slide>
        <Slide
          bgImage={
            <span
              className="background bg-cover"
              style={{
                backgroundImage: `url('/assets/webslides/frameland/editor2.png')`,
                backgroundSize: "contain",
              }}
            ></span>
          }
        ></Slide>

        <Script
          src="/assets/webslides/js/webslides.js"
          onLoad={() => {
            const script = document.createElement("script")
            script.textContent = "window.ws = new WebSlides()"
            document.head.appendChild(script)
          }}
        />
      </article>
    </main>
  )
}

function Slide({ title, topTitle, bgImage, children }) {
  const bodyStyle = {
    fontSize: "min(4vw, 36px)",
  }

  return (
    <section>
      {topTitle && <Title title={topTitle} />}
      {bgImage && bgImage}
      <div className="wrap aligncenter">
        {title && <Title title={title} />}
        <span style={bodyStyle}>{children}</span>
      </div>
    </section>
  )
}

function Title({ title }) {
  return (
    <h1
      className="uppercase tracking-wider text-fl-turkis-light text-center mb-8"
      style={{ fontFamily: ["Quicksand", "ui-serif", "Georgia"], fontSize: "min(6vw, 80px)" }}
    >
      {title}
    </h1>
  )
}

function Liste({ items }) {
  return (
    <ul className="list-none">
      {items.map(item => {
        return (
          <li className="ml-0 leading-loose" key={item}>
            {item}
          </li>
        )
      })}
    </ul>
  )
}

function Trailer() {
  const src = "https://www.youtube-nocookie.com/embed/T87JjxBXSng?si=SE4CGF3a9OW9HjPP"
  const title = "Frameland - Gameplay Trailer"

  return (
    <div
      style={{ width: "100%", maxWidth: "100%" }}
      className="relative aspect-video drop-shadow-2xl border-2 border-white/30"
    >
      <iframe
        className="absolute left-0 right-0 top-0 bottom-0"
        width="100%"
        height="100%"
        src={src}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export function Head() {
  return (
    <DefaultHead title="Frameland: Slides" description="Frameland: Slides">
      <link
        rel="stylesheet"
        type="text/css"
        media="all"
        href="/assets/webslides/css/webslides.css"
      />
    </DefaultHead>
  )
}

export default Slides
